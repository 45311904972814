import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import * as _ from 'lodash';

interface CompanySettings {
  ssoEnabled: boolean;
  ssoRequired: boolean;
  ssoUserProvisioningEnabled: boolean;
  ssoIdpCertificate: string;
  ssoIdpUrl: string;
  ssoIdpId: string;
  ssoSpUrl: string;
  ssoSpId: string;
}

interface Company {
  isChargebee: boolean;
  settings: CompanySettings;
}

@Component({
  selector: 'sso-settings',
  templateUrl: './sso-settings.component.html'
})
export class SSOSettingsComponent implements OnInit {
  @ViewChild('companyForm') companyForm: NgForm;

  @Output() cancelEvent = new EventEmitter<void>();

  companyId = this.companyStateService.getSelectedCompanyId();
  loading = false;
  formError = null;
  isRiseStoreAdmin = this.userStateService.isRiseStoreAdmin();
  certificateEditable = false;
  company: Company = {
    settings: {
      ssoRequired: false,
      ssoUserProvisioningEnabled: false,
      ssoIdpId: '',
      ssoIdpUrl: '',
      ssoIdpCertificate: '',
      ssoEnabled: false,
      ssoSpUrl: '',
      ssoSpId: ''
    },
    isChargebee: false
  };

  get loginUrl(): string {
    return `https://apps.risevision.com/provision?cid=${this.companyId}`;
  }

  constructor(
    private userStateService: UserStateService,
    private companyApiService: CompanyApiService,
    private processErrorCode: ProcessErrorCode,
    private companyStateService: CompanyStateService,
    private tracker: TrackerService,
  ) { }

  ngOnInit() {
    if (this.companyId) {
      this.loading = true;
      this.companyApiService.getCompany(this.companyId)
        .then((company) => {
          this.company = {
            ...company,
            settings: {
              ...company.settings,
              ssoRequired: company.settings.ssoRequired === 'true',
              ssoUserProvisioningEnabled: company.settings.ssoUserProvisioningEnabled === 'true',
              ssoEnabled: company.settings.ssoEnabled === 'true'
            }
          };
          this.certificateEditable = !this.company.settings.ssoIdpCertificate;
        })
        .catch((resp) => {
          this._showErrorMessage(resp);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  onSSORequiredChange(): void {
    if (!this.company.settings.ssoRequired) {
      this.company.settings.ssoUserProvisioningEnabled = false;
    }
  }

  editCertificate(): void {
    this.certificateEditable = true;
  }

  private _clearErrorMessages() {
    this.formError = null;
  }

  private _showErrorMessage(error: any) {
    this.formError = this.processErrorCode(error);
  }

  save() {
    this._clearErrorMessages();

    if (!this.companyForm.valid) {
      console.log('Form not valid');
    } else {
      this.loading = true;

      var company = _.cloneDeep(this.company);
      this._verifyAdmin(company);
      // Convert settings to strings before saving
      const settings = company.settings;
      settings.ssoRequired = settings.ssoRequired === true;
      settings.ssoUserProvisioningEnabled = settings.ssoUserProvisioningEnabled === true;
      settings.ssoEnabled = settings.ssoEnabled === true;
      return this.companyApiService.updateCompany(this.companyId, company)
        .then(() => {
          this.tracker.companyEvent('Company Updated', this.companyStateService.getSelectedCompanyId(),
            this.companyStateService.getSelectedCompanyName(), !this.companyStateService.isSubcompanySelected());

          this.companyStateService.updateCompanySettings(this.company);
        })
        .catch((error) => {
          this._showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private _verifyAdmin(company) {
    if (!this.isRiseStoreAdmin) {
      //exclude fields from API call
      delete company.shareCompanyPlan;
      delete company.unlockAllFeatures;
    }
  }

  onSSOEnabledChange(): void {
    if (!this.company.settings.ssoEnabled) {
      this.company.settings.ssoRequired = false;
      this.company.settings.ssoUserProvisioningEnabled = false;
    }
  }

  private copyUrl(url: string) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(url);
    }
  }

  copyLoginUrl() {
    this.copyUrl(this.loginUrl);
  }

  copyAcsUrl() {
    this.copyUrl(this.company.settings.ssoSpUrl);
  }

  copySpId() {
    this.copyUrl(this.company.settings.ssoSpId);
  }
}