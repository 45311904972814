import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import * as angular from 'angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { setTheme } from 'ngx-bootstrap/utils';
import { environment } from 'src/environments/environment';
import {
  $lazyLoadProvider,
  analyticsFactoryProvider,
  bigQueryLoggingProvider,
  blobDownloaderFactoryProvider,
  canAccessAppsProvider,
  companyAssetsServiceProvider,
  coreAPILoaderProvider,
  countriesProvider,
  downloadServiceProvider,
  exceptionHandlerProvider,
  fileDownloaderProvider,
  gadgetsApiProvider,
  gapiLoaderProvider,
  helpWidgetFactoryProvider,
  insecureUrlProvider,
  loadingServiceProvider,
  openidConnectLoaderProvider,
  outsideClickHandlerProvider,
  processErrorCodeProvider,
  proxyFileDownloaderProvider,
  scrollingListServiceProvider,
  storeAuthorizationProvider,
  urlStateServiceProvider
} from './ajs-upgraded-providers';
import { AuthModule } from './auth/auth.module';
import { BillingModule } from './billing/billing.module';
import { CommonHeaderModule } from './common-header/common-header.module';
import { ComponentsModule } from './components/components.module';
import { DisplaysModule } from './displays/displays.module';
import { EditorModule } from './editor/editor.module';
import { PurchaseModule } from './purchase/purchase.module';
import { SchedulesModule } from './schedules/schedules.module';
import { ScreenSharingModule } from './screen-sharing/screen-sharing.module';
import { IconService } from './shared/services/icon.service';
import { SharedModule } from './shared/shared.module';
import { StorageModule } from './storage/storage.module';
import { TemplateEditorModule } from './template-editor/template-editor.module';
import { UIRouterModule } from '@uirouter/angular';
import { routes } from './app.routes';
import { CommonHeaderComponent } from './common-header/components/common-header/common-header.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

@NgModule({
  declarations: [],
  bootstrap: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    ModalModule.forRoot(),
    UIRouterUpgradeModule.forRoot(),
    UIRouterModule.forChild(routes),
    SharedModule,
    BillingModule,
    CommonHeaderModule,
    ComponentsModule,
    BillingModule,
    EditorModule,
    DisplaysModule,
    PurchaseModule,
    TemplateEditorModule,
    StorageModule,
    AuthModule,
    SchedulesModule,
    ScreenSharingModule
  ],
  providers: [
    $lazyLoadProvider,
    loadingServiceProvider,
    exceptionHandlerProvider,
    analyticsFactoryProvider,
    openidConnectLoaderProvider,
    bigQueryLoggingProvider,
    canAccessAppsProvider,
    fileDownloaderProvider,
    proxyFileDownloaderProvider,
    blobDownloaderFactoryProvider,
    helpWidgetFactoryProvider,
    processErrorCodeProvider,
    scrollingListServiceProvider,
    gapiLoaderProvider,
    coreAPILoaderProvider,
    downloadServiceProvider,
    gadgetsApiProvider,
    storeAuthorizationProvider,
    urlStateServiceProvider,
    insecureUrlProvider,
    countriesProvider,
    outsideClickHandlerProvider,
    companyAssetsServiceProvider,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: (iconService: IconService) => {
        iconService.registerIcons();
      },
      deps: [IconService]
    }
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {
    setTheme('bs3');
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
  	console.log('Bootstraping AngularJS');
    this.upgrade.bootstrap(document.documentElement, ['risevision.apps']);
    appRef.bootstrap(CommonHeaderComponent);
  }
}

angular.module('risevision.common.config')
  .value('environment', environment);
