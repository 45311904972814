<div rvSpinner="sso-settings" [rvShowSpinner]="loading">
  <form #companyForm="ngForm">
    @if (formError) {
      <div class="alert alert-danger tw-mt-2" role="alert">
        {{ formError }}
      </div>
    }
    <div class="tw-w-full tw-flex tw-flex-col tw-justify-between">
      <div class="tw-flex tw-flex-col">
        <div class="tw-w-full">
          <div class="tw-flex left-right-aligner tw-pt-10">
            <label id="company-settings-sso-enabled-label" for="company-settings-sso-enabled"
              class="control-label tw-text-3xl">
              Enable SAML Single Sign On (SSO)
            </label>
            <mat-slide-toggle class="mb-2" name="ssoEnabled" id="company-settings-sso-enabled"
              aria-labelledby="company-settings-sso-enabled-label" [(ngModel)]="company.settings.ssoEnabled"
              (change)="onSSOEnabledChange()">
            </mat-slide-toggle>
          </div>

          @if (!company.settings.ssoEnabled) {
          <div class="tw-w-full tw-text-1xl tw-mt-1 tw-mb-8 tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-mt-10 tw-pt-10">
            Use the following information to create an application within your Identity Provider.
          </div>
          <div class="tw-flex md:!tw-flex-row tw-flex-col tw-gap-4 tw-w-full tw-pt-2 tw-pb-2">
            <div class="tw-w-full">
              <label class="control-label tw-text-2xl">Assertion Consumer Service URL:</label>
              <div class="tw-flex tw-gap-2 tw-items-center tw-mt-2">
                <span type="text" class="tw-truncate">
                  {{company.settings.ssoSpUrl}}
                </span>
                <button class="btn btn-default-icon tw-border-0" (click)="copyAcsUrl()"
                  aria-label="Copy Assertion Consumer Service URL">
                  <streamline-icon name="copy" width="10" height="10"></streamline-icon>
                </button>
              </div>
            </div>
            <div class="tw-w-full">
              <label class="control-label tw-text-2xl">Service Provider Entity ID:</label>
              <div class="tw-flex tw-gap-2 tw-items-center tw-mt-2">
                <span type="text" readonly class="tw-truncate">
                  {{company.settings.ssoSpId}}
                </span>
                <button class="btn btn-default-icon tw-border-0" (click)="copySpId()"
                  aria-label="Copy Service Provider Entity ID">
                  <streamline-icon name="copy" width="10" height="10"></streamline-icon>
                </button>
              </div>
            </div>
          </div>
          }
          @else {
          <div class="tw-flex md:!tw-flex-row tw-flex-col tw-gap-4 tw-w-full tw-mt-10 tw-pt-10 tw-pb-10 tw-border-0 tw-border-t tw-border-solid tw-border-gray-200">
            <div class="tw-w-full tw-flex tw-flex-col">
              <label class="control-label tw-text-2xl">
                SSO Provider ID
              </label>
              <input class="form-control tw-truncate"
                [disabled]="!company.settings.ssoEnabled"
                name="ssoIdpId"
                #ssoIdpId="ngModel"
                [(ngModel)]="company.settings.ssoIdpId" required>
              <p [shown]="(ssoIdpId.dirty || companyForm?.submitted) && ssoIdpId.errors?.required"
                class="help-block validation-error-message-name text-danger">
                Identity Provider ID is required.
              </p>
            </div>

            <div class="tw-w-full tw-flex tw-flex-col">
              <label class="control-label tw-text-2xl">
                SSO Provider URL
              </label>
              <input class="form-control tw-truncate"
                [disabled]="!company.settings.ssoEnabled"
                name="ssoIdpUrl"
                #ssoIdpUrl="ngModel"
                [(ngModel)]="company.settings.ssoIdpUrl" required>
              <p [shown]="(ssoIdpUrl.dirty || companyForm?.submitted) && ssoIdpUrl.errors?.required"
                class="help-block validation-error-message-name text-danger">
                Identity Provider URL is required.
              </p>
            </div>

            <!-- X.509 Certificate Section -->
            <div class="tw-w-full">
              <label for="company-settings-ssoidpcertificate" class="control-label tw-text-2xl">
                X.509 Certificate
              </label>
              @if (certificateEditable) {
              <div>
                <textarea id="company-settings-ssoidpcertificate"
                  name="company-settings-ssoidpcertificate" #ssoIdpCertificate="ngModel" required
                  type="text" rows="4" class="form-control"
                  [(ngModel)]="company.settings.ssoIdpCertificate"
                  [disabled]="!company.settings.ssoEnabled">
                </textarea>
                <p [shown]="(ssoIdpCertificate.dirty || companyForm?.submitted) && ssoIdpCertificate.errors?.required"
                  class="help-block validation-error-message-name text-danger">
                  Identity Provider x509 Certificate is required.
                </p>
              </div>
              }
              @else {
              <div class="tw-flex left-right-aligner">
                <input id="company-settings-ssoidpcertificate-label"
                  name="company-settings-ssoidpcertificate-label" #ssoIdpCertificate="ngModel"
                  type="text" class="form-control" [(ngModel)]="company.settings.ssoIdpCertificate"
                  readonly>
                <button class="btn btn-default-icon pl-3 pr-3 ml-2 tw-mr-1"
                  aria-label="Edit X.509 Certificate" (click)="editCertificate()"
                  [disabled]="!company.settings.ssoEnabled">
                  <streamline-icon ariaLabel="Edit X.509 Certificate" name="pencil" width="12"
                    height="12"></streamline-icon>
                </button>
              </div>
              }
            </div>
          </div>
          }
        </div>

        <div class="tw-w-full tw-pt-10 tw-pb-10 tw-border-0 tw-border-b tw-border-t tw-border-solid tw-border-gray-200"
          [hidden]="!company.settings.ssoEnabled">
          <div class="tw-flex left-right-aligner">
            <label id="company-settings-ssorequired-label" for="company-settings-ssorequired"
              class="control-label tw-text-2xl">
              Require SSO for all users
            </label>
            <mat-slide-toggle class="mb-2" name="ssoRequired" id="company-settings-ssorequired"
              aria-labelledby="company-settings-ssorequired-label"
              [(ngModel)]="company.settings.ssoRequired" [disabled]="!company.settings.ssoEnabled"
              (change)="onSSORequiredChange()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="tw-w-full tw-pt-10" [hidden]="!company.settings.ssoEnabled">
          <div *requireRole="'sa'">
            <div class="tw-flex left-right-aligner">
              <label id="company-settings-sso-user-provisioning-label"
                for="company-settings-sso-user-provisioning"
                class="control-label tw-text-2xl rise-admin-only">
                Enable SSO User Provisioning
              </label>
              <mat-slide-toggle name="ssoUserProvisioningEnabled"
                [(ngModel)]="company.settings.ssoUserProvisioningEnabled"
                [disabled]="!company.settings.ssoEnabled || !company.settings.ssoRequired"
                id="company-settings-sso-user-provisioning">
              </mat-slide-toggle>
            </div>
            <div class="tw-text-1xl tw-text-gray-500 tw-mt-1 tw-mb-8">
              Enabling this setting manages users through your IdP and requires SSO for all users. <br />
            </div>
            <div class="tw-text-1xl tw-text-gray-500 tw-mt-1 tw-mb-8"
              [hidden]="!company.settings.ssoUserProvisioningEnabled">
              Please confirm that all users have the appropriate user roles assigned through the IdP.
              Users who do not
              have user roles will not have access to Rise Vision. <br />
              <a class="tw-underline tw-text-blue-500" target="_blank"
                href="https://help.risevision.com/hc/en-us/articles/14653732546580-How-do-I-enable-Single-Sign-On-for-Rise-Vision">Learn
                more about SSO User Provisioning</a>
            </div>

            <notification-alert title="First time user login instructions." type="info"
              class="tw-text-1xl tw-leading-loose"
              [hidden]="!company.settings.ssoUserProvisioningEnabled">
              When logging in for the first time your users will need to sign in through your IdP portal
              or you can
              instruct them to use the following url: <br />
              <div class="tw-flex tw-items-center tw-gap-2">
                <input type="text" [value]="loginUrl"
                  class="form-control tw-w-2/4 tw-h-10 tw-rounded-none tw-bg-black/5 tw-border-0 tw-truncate"
                  readonly />
                <a class="tw-cursor-pointer tw-ml-4" (click)="copyLoginUrl()">Copy URL</a>
              </div>
            </notification-alert>
          </div>
        </div>
      </div>

      <div class="button-row text-right tw-mt-8">
        <button id="cancelButton" class="btn btn-default btn-toolbar" (click)="cancelEvent.emit()">
          Cancel
        </button>
        <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" (click)="save()">
          Save
        </button>
      </div>
    </div>
  </form>
</div>