import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { CookieService } from 'ngx-cookie-service';

import { CustomAuthService } from './services/custom-auth.service';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { RegistrationComponent } from './components/registration/registration.component';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { routes } from './auth.routes';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { LoginComponent } from './components/login/login.component';
import { AuthFormComponent } from './components/auth-form/auth-form.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { RvTokenStoreService } from './services/rv-token-store.service';
import { UserStateService } from './services/user-state.service';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { GoogleAuthService } from './services/google-auth.service';
import { CompanyStateService } from './services/company-state.service';
import { AuthCommonComponent } from './components/auth-common/auth-common.component';
import { UserAuthService } from './services/user-auth.service';

@NgModule({
  declarations: [
    RequestPasswordResetComponent,
    ResetPasswordConfirmComponent,
    RegistrationComponent,
    UnsubscribeComponent,
    LoginComponent,
    AuthFormComponent,
    CreateAccountComponent,
    ConfirmEmailComponent,
    AuthCommonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ComponentsModule,
    SharedModule,
    CommonHeaderModule
  ],
  providers: [
    CookieService
  ]
})
export class AuthModule {
    //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [ ];
  static providers = [ UserAuthService, UserStateService, CompanyStateService, GoogleAuthService, CustomAuthService, RvTokenStoreService ];
}
